<template>
    <div class="content">
        <div>
          <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
          <el-button type="primary" class="el-icon-bottom" @click="Send"> 全部下发</el-button>
        </div>

        <el-table :data='$store.state.DevDataList' border style="width: 100%;height: calc(100vh - 140px);max-height: calc(100vh - 140px);overflow-y: scroll;" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="dev_name" label="设备名称"></el-table-column>
            <el-table-column align="center" prop="dev_type" label="设备类型">
                <template slot-scope="scope">
                    {{scope.row.dev_type===0?'相机':(scope.row.dev_type===1?"Lcd屏":(scope.row.dev_type===2?"余位屏":""))}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="dev_ip" label="IP"></el-table-column>
            <el-table-column align="center" prop="dev_mac" label="序列号"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click="EditClick(scope.row)">修改</el-link>
                    <el-link type="danger" @click="DelClick(scope.row)">删除</el-link>
                    <el-link v-if="scope.row.dev_type===0" type="primary" @click="GetWeb(scope.row)">远程</el-link>
                </template>
            </el-table-column>
        </el-table>

        <!--弹框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" :width='dialogWidth' :append-to-body='true'>
            <el-form :model="devForm" :rules="rules" ref="devForm" label-width="100px" size='medium'>
                <el-form-item label="设备名称" prop="dev_name">
                    <el-input v-model="devForm.dev_name"></el-input>
                </el-form-item>
                <el-form-item label="设备类型">
                    <el-select v-model="devForm.dev_type" style="width:100%;">
                      <el-option label="摄像机" :value='0'></el-option>
                      <el-option label="Lcd屏" :value='1'></el-option>
                      <el-option label="余位屏" :value='2'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="IP 地址"  prop="dev_ip">
                    <el-input v-model="devForm.dev_ip"></el-input>
                </el-form-item>
                <el-form-item label="序列号" v-if="devForm.dev_type===0">
                    <el-input v-model="devForm.dev_mac"></el-input>
                </el-form-item>
                <el-form-item label="尺寸大小" v-if="devForm.dev_type===2">
                  <div class="a">
                    <el-input-number v-model="devForm.dev_yw_width" :min="1" label="宽" controls-position="right"></el-input-number>
                    <span style="margin-left: 5px;margin-right: 5px;">*</span>
                    <el-input-number v-model="devForm.dev_yw_height" :min="1" label="高" controls-position="right"></el-input-number>
                  </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      dialogVisible: false,
      title: '',
      devForm: {
        dev_id: 0,
        dev_name: '',
        dev_type: 0,
        dev_ip: '',
        dev_mac: '',
        dev_yw_width:0,
        dev_yw_height:0
      },
      rules: {
        dev_name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        dev_ip: [{ required: true, message: '请输入设备ip', trigger: 'blur' }]
      },
      isFinish: true,
      dialogWidth:'450px'
    }
  },
  created () {
    if(this.$store.state.DevDataList.length===0){
      this.$store.dispatch('getDevDataList')
    }
  },
  methods: {
    AddClick () {
      if(util.CheckUserAuth('1-1-1')){
        this.devForm.dev_id = 0
        this.devForm.dev_name = ''
        this.devForm.dev_type = 0
        this.devForm.dev_ip = ''
        this.devForm.dev_mac = ''
        this.title = '新增'
        this.dialogVisible = true
      }
    },
    EditClick (row) {
      if(util.CheckUserAuth('1-1-2')){
        var data = JSON.stringify(row)
        this.devForm = JSON.parse(data)
        this.title = '修改'
        this.dialogVisible = true
      }
    },
    DelClick (row) {
      if(util.CheckUserAuth('1-1-3')){
        this.$confirm('确定删除设备“' + row.dev_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('dev/del?id=' + row.dev_id+'&name='+row.dev_name).then(res => {
            if (res.rpStatus === 10000) {
              this.$store.dispatch('getDevDataList') 
            } else { this.$message.error(res.rpMsg) }
          })
        }).catch(e => e)
      }
    },
    submit () {
      this.$refs.devForm.validate((valid) => {
        if (valid) {
          util.Post('dev/edit', this.devForm).then(res => {
            if (res.rpStatus === 10000) {
              this.$store.dispatch('getDevDataList')
              this.dialogVisible = false
            } else { this.$message.error(util.ErrorMsg(res.rpStatus)) }
          })
        }
      })
    },
    Send () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('dev').then(res=>{
          this.isFinish=true
        })
      }
    },
    GetWeb(row){
      if(!row.dev_mac){
        this.$message.error('请修改输入设备序列号后，再远程')
        return
      }
      util.Get('dev/getdevweb?mac='+row.dev_mac).then(res=>{
        let body= JSON.parse(res.body)
        if(res.statusCode===400){
          this.$message.error(body.message)
          return
        }
        window.open(body.url)
      })
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.a{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
